<template>
  <div class="startup">
    <div class="auth-logo text-center mt-4">
      <img :src="logo" alt="" />
    </div>
    <div class="container-fluid px-80">
      <b-row>
        <b-col lg="12" class="text-center mb-30">
          <img
            src="@/assets/images/faces/13.jpg"
            class="mb-30"
            alt
            style="border-radius: 50%"
          />
          <h1 class="mb-12">Good morning, John.</h1>
          <a href="">Account Setting</a>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
            <b-card
              class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden ripple text-center p-24 cursor-pointer"
              @click="navigate('dashboard')"
            >
              <b-icon
                icon="layout-text-window-reverse"
                variant="primary"
                style="width: 50px; height: 50px"
              ></b-icon>
            </b-card>
            <p>Dashboard</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
            @click="navigate('purchase-order')"
          >
            <b-icon
              icon="cart"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Purchase</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
            @click="navigate('sales-order')"
          >
            <b-icon
              icon="bag"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Sales</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
            @click="navigate('sales-order')"
          >
            <b-icon
              icon="file-bar-graph"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Slot status</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
          >
            <b-icon
              icon="file-earmark-ruled"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Return filling</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
          >
            <b-icon
              icon="clipboard"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Approval and dues</p>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
            @click="navigate('accounts')"
          >
            <b-icon
              icon="bank2"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Accounts</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
            @click="navigate('accounts')"
          >
            <b-icon
              icon="exclamation-octagon"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Reports</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
            @click="navigate('inventory')"
          >
            <b-icon
              icon="archive"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Inventory</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
          >
            <b-icon
              icon="people"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>Partners</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
          >
            <b-icon
              icon="person-lines-fill"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>User Management</p>
          </div>
        </b-col>
        <b-col lg="2" md="6" sm="6" class="py-24 text-center">
          <div class="d-flex flex-column align-items-center">
          <b-card
            class="card-icon-bg card-icon-bg-primary o-hidden mb-30 o-hidden text-center p-24 cursor-pointer"
          >
            <b-icon
              icon="gear"
              variant="primary"
              style="width: 50px; height: 50px"
            ></b-icon>
          </b-card>
          <p>General Settings</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "Startup"
  },
  data() {
    return {
      logo: require("@/assets/images/logo.png"),
    };
  },
  methods: {
    navigate(path) {
      if(path === 'dashboard') {
        this.$router.push('/app/dashboards/dashboard')
      } else {
        this.$router.push("/"+path)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  // max-width: 90px;
  .card-body {
    padding: 0px !important;
    justify-content: center;
  }
}
.startup {
  background: rgb(226, 219, 249);
  background: radial-gradient(
    circle,
    rgba(226, 219, 249, 1) 0%,
    rgba(231, 243, 250, 1) 56%,
    rgba(231, 243, 250, 1) 100%
  );
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-size: cover;
}
.auth-logo {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 50px;
}
.auth-logo img {
    width: 60px;
    height: 60px;
}
.card.card-icon-bg:hover svg{
    color: #fff !important;
}

.card.card-icon-bg:hover{
    background: #0059ad !important;
}

</style>
